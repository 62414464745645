































































import { VerkaufsStatistik } from "@/models/util/BuchVerlagsVerkaeufeDisplayModel";
import Vue from "vue";
import { catVue } from "@/util/logging";
import { ulid } from "ulid";

//import XLSX from "xlsx";

   export default Vue.extend({
     //
     name: "BuchVerlagsVerkäufeDetail",

     props: {
       item: Object,
     },

     data() {
       return {
         // For the Excel-Export to find the element
         id: "",
         // For the Data-Table
         itemsPerPage: 10,
         exporting: false,
       };
     },

     methods: {
       getExcel(): void {
         catVue.info("GetExcel clicked! " + JSON.stringify(this.item));
         let exporter = () =>
           import(/* webpackChunkName: "excel-support" */ "xlsx").then(
             (module) => {
               const XLSX = module.default;

               let filename = "output";
               if (this.item.buchVerlag?.vn) {
                 filename =
                   this.item.buchVerlag.vn + " " + this.item.buchVerlag.name;
               }

               const prev = this.itemsPerPage;
               this.itemsPerPage = this.item.details.length;
               this.exporting = true;

               this.$nextTick(() => {
                 let tbl = document.getElementById(this.id);
                 let wb = XLSX.utils.table_to_book(tbl);
                 XLSX.writeFile(wb, filename + ".xlsx");
                 this.$nextTick(() => {
                   this.exporting = false;
                   this.itemsPerPage = prev;
                 });
               });
             }
           );
         exporter();
       },
     },

     computed: {
       headers() {
         let ret = [];
         if (VerkaufsStatistik.viewString() == "verkauf") {
           ret = [
             { text: "Preis", align: "end", value: "verkauf" },
             { text: "EAN", align: "start", value: "EAN" },
             {
               text: "Titel (Erscheinen)",
               align: "start",
               value: "buchTitel",
             },
             {
               text: "Filiale",
               align: "start",
               value: "filiale",
               cellClass: "filiale",
             },
             {
               text: "Verkauf",
               value: "stueck",
               align: "end",
               cellClass: "mge-verkauf",
             },
             { text: "Umsatz", value: "umsatz", align: "end" },
             {
               text: "Lfg.",
               value: "lieferung",
               align: "end",
               cellClass: "mge-lieferung",
             },
             {
               text: "Lfg. Datum",
               value: "lieferungDatum",
               align: "end",
               cellClass: "mge-lieferung",
             },
             {
               text: "Remi",
               value: "remission",
               align: "end",
               cellClass: "mge-remission",
             },
             {
               text: "Remi Datum",
               value: "remissionDatum",
               align: "end",
               cellClass: "mge-remission",
             },
             { text: "Bestand", value: "bestand", align: "end" },
           ];
         } else {
           ret = [
             { text: "Preis", align: "end", value: "verkauf" },
             { text: "EAN", align: "start", value: "EAN" },
             {
               text: "Titel (Erscheinen)",
               align: "start",
               value: "buchTitel",
             },
             {
               text: "Filiale",
               align: "start",
               value: "filiale",
               cellClass: "filiale",
             },
             {
               text: "Lieferung",
               value: "stueck",
               align: "end",
               cellClass: "mge-lieferung",
             },
             {
               text: "Lfg. Datum",
               value: "datum",
               align: "end",
               cellClass: "mge-lieferung",
             },
             { text: "Warenwert", value: "umsatz", align: "end" },
             {
               text: "Remi",
               value: "remission",
               align: "end",
               cellClass: "mge-remission",
             },
             {
               text: "Remi Datum",
               value: "remissionDatum",
               align: "end",
               cellClass: "mge-remission",
             },
             { text: "Bestand", value: "bestand", align: "end" },
           ];
         }

         if (!this.item.buchVerlag || this.item.buchVerlag.vn === 0) {
           ret.push({
             text: "VN",
             value: "buchverlagVN",
             align: "end",
           });
           ret.push({
             text: "Verlag",
             value: "buchverlagName",
             align: "start",
           });
           ret.push({
             text: "Listen-Position",
             value: "position",
             align: "end",
           });
         }

         return ret;
       },
     },

     mounted() {
       catVue.info(`${this.$options.name}:: MOUNTED ... `);
       // eslint-disable-next-line
       this.id = ulid();
     },
   });
