var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.details)?_c('div',[_c('v-data-table',{attrs:{"loading":_vm.item.detStep < 3,"multi-sort":"","headers":_vm.headers,"items":_vm.item.details,"item-key":"uid","id":_vm.id,"items-per-page":_vm.itemsPerPage,"footer-props":{
         showFirstLastPage: true,
         'items-per-page-text': 'Zeilen pro Seite',
      }},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.buchTitel",fn:function(ref){
      var item = ref.item;
return [(_vm.exporting)?_c('span',[_vm._v(" "+_vm._s(item.buchTitel)+" ("+_vm._s(item.evt)+") "+_vm._s(item.auflage)+" ")]):[_vm._v(" "+_vm._s(item.buchTitel)+" "),_c('i',{staticClass:"titel"}),(item.buchEVT != '')?_c('i',{staticClass:"evt"},[_vm._v(" ("+_vm._s(item.evt)+")  ")]):_vm._e(),(item.auflage)?_c('i',{staticClass:"auflage"},[_vm._v(" "+_vm._s(item.auflage)+" ")]):_vm._e()]]}},{key:"item.filiale",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.filiale)+": "+_vm._s(item.filialeName)+" ")]}},{key:"item.umsatz",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.umsatzString)+" ")]}},{key:"item.verkauf",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.verkaufString)+" ")]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" Detail: Zeilen "+_vm._s(items.pageStart)+" - "+_vm._s(items.pageStop)+" (von "+_vm._s(items.itemsLength)+") ")]}}],null,true)}),_c('hr'),_c('div',{staticClass:"detail-options"},[(_vm.item.detStep == 3)?_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.getExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-microsoft-excel ")]),_vm._v(" Export nach Excel")],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }